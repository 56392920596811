import React from 'react';
import './css/index.css'
import Homepage from './pages/homepage';
function App() {
  return (
    <div>
      <Homepage />
    </div>
  );
}

export default App;
