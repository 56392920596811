import React from "react";

function ServiceCard({name,iconclass}) {
  return (
    <div className="col-md-3">
      <div className="card servicecard">
        <div className="card-body text-center">
          <div className="iconSection">
            <h1 className="cardIcon text-center">
              <i className={iconclass}></i>
            </h1>
          </div>
          <h2>{name}</h2>
          {
            name === "Networking" ? <><br /></> : <></>
          }
          {/* <h3 className="readMore">Read More</h3> */}
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
