import React, { useState } from "react";
import "../css/navbar.css";

function Navbar() {
  const [isactive, setIsActive] = useState("not-active");
  const [isactive1, setIsActive1] = useState("not-active");
  const [isactive2, setIsActive2] = useState("not-active");
  const [isactive3, setIsActive3] = useState("not-active");

  const closeNavbar = () => {
    if (isactive === "not-active") {
      setTimeout(() => setIsActive1("active"), 500);
      setTimeout(() => setIsActive2("active"), 600);
      setTimeout(() => setIsActive3("active"), 700);
      setTimeout(() => setIsActive("active"), 800);
    } else {
      setTimeout(() => setIsActive("not-active"), 500);
      setTimeout(() => setIsActive3("not-active"), 600);
      setTimeout(() => setIsActive2("not-active"), 800);
      setTimeout(() => setIsActive1("not-active"), 1000);
    }
  };
  const navigate = (id) => {
    closeNavbar();
    document.getElementById(id).scrollIntoView();
  };
  return (
    <>
      <div className="navSec">
        <div className="navBack"></div>
        <div className="navbarDiv">
          <h3 className="logo">
            Masters Computer <span className="enterprises">Enterprise</span>
          </h3>
          <div className="navbarBtn" onClick={() => closeNavbar()}>
            {isactive === "not-active" ? (
              <i className="fa-solid fa-align-justify"></i>
            ) : (
              <i className="fa-solid fa-xmark"></i>
            )}
          </div>
        </div>
      </div>
      <div className={`navigation-style-1 ${isactive1}`}></div>
      <div className={`navigation-style-2 ${isactive2}`}></div>
      <div className={`navigation-style-3 ${isactive3}`}></div>
      <div className={`navigation ${isactive}`}>
        <ul>
          <li>
            <input
              type="button"
              value="Home"
              onClick={() => navigate("home")}
            />
          </li>
          <li>
            <input
              type="button"
              value="Services"
              onClick={() => navigate("service")}
            />
          </li>
          <li>
            <input
              type="button"
              value="Dealers"
              onClick={() => navigate("dealer")}
            />
          </li>
          <li>
            <input
              type="button"
              value="Customers"
              onClick={() => navigate("customer")}
            />
          </li>
          <li>
            <input
              type="button"
              value="About Us"
              onClick={() => navigate("aboutus")}
            />
          </li>
          <li>
            <input
              type="button"
              value="Team"
              onClick={() => navigate("team")}
            />
          </li>
          <li>
            <input
              type="button"
              value="Contact Us"
              onClick={() => navigate("meetus")}
            />
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
