// component imports
import Navbar from "../components/navbar";
import Copyright from "../components/Copyright";
import bg from "../images/bg.png";

// CSS imports
import "../css/homepage.css";

// Image Import
import ServiceCard from "../components/serviceCard";
import about from "../images/aboutUs.png";
// Customer
import peninsulaLogo from "../images/customers/peninsulaLogo.png";
import ascotIn from "../images/customers/ascotLogo.png";
import humancare from "../images/customers/humancare.png";
// team
import farid from "../images/team/farid.png";
import zia from "../images/team/zia.png";
import tarveen from "../images/team/tarveen.png";
// dealer
import canon from "../images/dealer/canon.png";
import dell from "../images/dealer/dell.png";
import epson from "../images/dealer/epson.png";
import hp from "../images/dealer/hp.png";
import lenovo from "../images/dealer/lenovo.png";
import cpplus from "../images/dealer/cpplus.png";
import hikvision from "../images/dealer/hikvision.png";
import intel from "../images/dealer/Intel_logo.png";
import gigabyte from "../images/dealer/gigabytelogo.png";
import brother from "../images/dealer/brother.png";
import acer from "../images/dealer/acer.png";
import tplink from "../images/dealer/tplink.png";
import dlink from "../images/dealer/dlinklogo.png";
import cisco from "../images/dealer/cisco.png";
import apc from "../images/dealer/apc.png";
import quickheal from "../images/dealer/quickheal.png";
import tally from "../images/dealer/tally.png";
import excel from "../images/customers/excel.png";
import bonhomie from "../images/customers/bonohomie.png";
import ngo2 from "../images/customers/ngo3.png";
import ngo1 from "../images/customers/khushingo.png";
import ahs1 from "../images/customers/logo1.png";
import ahs2 from "../images/customers/logo2.png";
import ahs3 from "../images/customers/logo3.png";
import aks from "../images/customers/aks.png";
import azaan from "../images/customers/azaan.png";
import regalo from "../images/customers/regalo.png";

function Homepage() {
  return (
    <>
      <Navbar />
      {/* Welcome section */}
      <section id="home">
        <img loading="lazy" className="bg" src={bg} alt="img" />
        <div className="content">
          <h1 className="sectionTitle">
            Masters Computer <span className="enterprises">Enterprise</span>
          </h1>
          <p className="pcontent">
            In a state of ever-growing and rapidly evolving market for IT and
            system integration, recognizing the need of a company that can offer
            high quality service in local market, a Masters Computer Enterprise
            founded.
          </p>
        </div>
      </section>
      {/* services */}
      <div className="services" id="service">
        <div className="container-fluid">
          <div className="row">
            <h1 className="col-12 text-center">Our Services</h1>
          </div>
          <div className="row">
            {/* Info tech trading */}
            {/*  */}
            <ServiceCard
              name={"Computer/Laptop Sales & Repair"}
              iconclass={"fa-solid fa-computer"}
            />
            <ServiceCard
              name={"Security & Surveillance"}
              iconclass={"fa-solid fa-shield-halved"}
            />
            <ServiceCard
              name={"Networking"}
              iconclass={"fa-solid fa-network-wired"}
            />
            <ServiceCard
              name={"ERP / Software Sale"}
              iconclass={"fa-solid fa-database"}
            />
          </div>
        </div>
      </div>
      {/* Our dealers */}
      <br />
      <br />
      <br />
      <div className="dealers" id="dealer">
        <div className="container-fluid">
          <div className="row">
            <h1 className="col-12 text-center">We deal with</h1>
          </div>
          <br />
          <div className="row dealerArea">
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={intel}
                    className="canon"
                    alt="intel"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={gigabyte}
                    className="canon"
                    alt="intel"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={canon}
                    className="canon"
                    alt="canon"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={epson}
                    className="canon"
                    alt="otherDeal"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={brother}
                    className="canon"
                    alt="otherDeal"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={tally}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={dell}
                    className="canon2"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={hp}
                    className="canon2"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={lenovo}
                    className="canon"
                    alt="otherDeal"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={acer}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={cpplus}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={hikvision}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={dlink}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={tplink}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={cisco}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={apc}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    loading="lazy"
                    src={quickheal}
                    className="canon"
                    alt="customer2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* customers */}
      <div className="customers" id="customer">
        <div className="container-fluid">
          <div className="row">
            <h1 className="col-12 text-center">Our Customers</h1>
          </div>
          <br />
          <br />
          <div className="row customersArea">
            <div className="col-md-4">
              <div className="row customerSection">
                <div className="col-12 text-center">
                  <h2>Hospitality Industries:</h2>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body text-center">
                      <div className="peninsula">
                        <img
                          loading="lazy"
                          src={peninsulaLogo}
                          className="canon3"
                          alt="customer2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body text-center">
                      <img
                        loading="lazy"
                        src={ascotIn}
                        className="canon"
                        alt="customer2"
                      />
                      <br />
                      <br />
                      <h2>Ascot Inn</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row customerSection">
                <div className="col-12 text-center">
                  <h2>Health Care Sector</h2>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={humancare}
                        className="canon4"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={excel}
                        className="canon"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row customerSection">
                <div className="col-12 text-center">
                  <h2>NGO's</h2>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={ngo1}
                        className="canon4"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={ngo2}
                        className="canon3"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row customerSection">
                <div className="col-12 text-center">
                  <h2>S.M.E Sector</h2>
                </div>

                <div className="col-md-4 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={bonhomie}
                        className="canon4"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={regalo}
                        className="canon4"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={azaan}
                        className="canon4"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row customerSection">
                <div className="col-12 text-center">
                  <h2>Educational Institute</h2>
                </div>

                <div className="col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={ahs1}
                        className="canon5"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={ahs2}
                        className="canon6"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={ahs3}
                        className="canon6"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="card">
                    <div className="card-body">
                      <img
                        loading="lazy"
                        src={aks}
                        className="canon4"
                        alt="humancare logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* About-us */}
      <div className="about-us" id="aboutus">
        <div className="row">
          <h1 className="col-12 text-center">Who Are We</h1>
        </div>
        <div className="row text-center about">
          <div className="col-md-6 aboutUsPar">
            <p>
              We have more than 30 years experience in Information Technology.
              The main objective of the company is to serve customers in Micro –
              Small – Medium enterprises in such areas as production, trade,
              medical, education institutions and NGO’s.
              <br />
              <br />
              There is team of highly qualified employees working in the
              organization, specializing in the following areas: Hardware,
              Software, Networking, Security Surveillance and services.
            </p>
          </div>
          <div className="col-md-4">
            <img
              loading="lazy"
              src={about}
              alt="about"
              className="aboutUsImg"
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* team */}
      <div className="team" id="team">
        <div className="row">
          <h1 className="col-12 text-center">Our Team</h1>
        </div>
        <div className="row teamRow">
          <div className="col-md-3">
            <div className="card">
              <div className="card-body text-center">
                <img
                  loading="lazy"
                  src={tarveen}
                  alt="farid"
                  className="team1"
                />
                <br />
                <br />
                <h3>Khan Tarveen Farid</h3>
                <br />
                <br />
                <h4 className="text-muted">
                  Proprietor /<br />
                  Executive Manager
                </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body text-center">
                <img loading="lazy" src={zia} alt="farid" className="team2" />
                <br />
                <br />
                <h3>Zia Ur Rahman</h3>
                <br />
                <br />
                <h4 className="text-muted">General Manager</h4>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card">
              <div className="card-body text-center">
                <img loading="lazy" src={farid} alt="farid" className="team3" />
                <br />
                <br />
                <h3>Khan Farid Ur Rahman</h3>
                <br />
                <h4 className="text-muted">
                  Business Development Manager /<br />
                  Technical Manager
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* contact us */}
      <div className="meetus" id="meetus">
        <div className="row">
          <div className="col-md-8 nonMapArea">
            <div className="text-center">
              <h2>
                <b>Got some issues or need to get some quotation ?</b>
              </h2>
              <h3>Feel Free to contact us</h3>
            </div>
            <br />
            <div className="row">
              <div className="col-md-4 text-center">
                <i className="contactIcon fa-solid fa-phone"></i>
                <br />
                <br />
                <br />
                <h3>CALL US:</h3>
                <br />
                <a className="phoneNumber" href="tel:8286064593">
                  8286064593
                </a>
                <br />
                <a className="phoneNumber" href="tel:7977884617">
                  7977884617
                </a>
              </div>
              <div className="col-md-4 text-center">
                <i className="contactIcon2 fa-solid fa-location-dot"></i>
                <br />
                <br />
                <br />
                <h3>ADDRESS:</h3>
                <br />
                <address className="address">
                  R-5A, 1st Floor, Abdul Munaf Chawl, Sakinaka Metro Station
                  Mumbai, Maharashtra 400072 India
                </address>
              </div>
              <div className="col-md-4 text-center">
                <i className="contactIcon fa-solid fa-envelope"></i>
                <br />
                <br />
                <br />
                <h3>EMAIL:</h3>
                <br />
                <a className="mail" href="mailto:farid@mcenterprise.in">
                  farid@mcenterprise.in
                </a><br />
                <a className="mail" href="mailto:Mceneterprise@gmail.com">
                  Mceneterprise@gmail.com
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 map-responsive">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.1062053279093!2d72.88580151443931!3d19.102996187072463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9071380f20d%3A0xf49eb08464468b12!2sMasters%20Computer%20Enterprise!5e0!3m2!1sen!2sin!4v1651121892368!5m2!1sen!2sin"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <Copyright />
    </>
  );
}

export default Homepage;
